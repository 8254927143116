<!--
 * @Author: your name
 * @Date: 2020-08-25 16:18:41
 * @LastEditTime: 2020-09-12 14:30:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\views\modules\productFlow\filtration\createdFilter\index.vue
-->
<template>
  <div class="wrapper1">
    <!-- <el-dialog title="高级查询"
               :visible.sync="dialogVisible"
               width="80%">
      <el-container>
        <el-aside width="200px">
          <div class="clearfix">
            所有的查询条件
          </div>
          <el-crad class="box-card">
            <el-row :gutter="20"
                    v-for="(item,index) in filtersList"
                    :key="index">
              <el-col :span="20">{{item.name}}</el-col>
              <el-col :span="4">
                <i class="el-icon-delete"
                   style="color:red"></i>
              </el-col>
            </el-row>
          </el-crad>
        </el-aside>
        <el-main>
            <div class="condition">
                <el-card class="box-card1">1</el-card>
                <el-card class="box-card1">1</el-card>
                <el-card class="box-card1">1</el-card>
                <el-card class="box-card1">1</el-card>
            </div>
        </el-main>
      </el-container> -->
    <el-card class="box-card">
      <div slot="header"
           class="clearfix">
        <span>创建新的高级查询条件</span>
      </div>
      <el-row :gutter="20"
              type="flex">
        <el-col :span="4">
          <el-input placeholder="高级查询名称"
                    v-model.trim="auditName"></el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="modelListSelect"
                     placeholder="模型"
                     @change="modelChange"
                     clearable
                     @clear="clearModel">
            <el-option v-for="(item,index) in modelList"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="agentListSelect"
                     placeholder="代理"
                     multiple
                     collapse-tags
                     filterable
                     @change="agentChange">
            <el-option v-for="(item,index) in agentList"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="expressListSelect"
                     placeholder="快递"
                     multiple
                     collapse-tags
                     filterable
                     @change="expressChange">
            <el-option v-for="(item,index) in expressList"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="supplyListSelect"
                     placeholder="供应商"
                     multiple
                     collapse-tags
                     filterable
                     @change="supplyChange">
            <el-option v-for="(item,index) in supplyList"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primary"
                     @click="addAudit" icon="el-icon-plus">新增</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20"
              type="flex"
              v-if="!isModel">
        <el-col :span="4"
                v-if="brandList!=[]">
          <el-select v-model="brandListSelect"
                     placeholder="品牌"
                     :multiple='true'
                     filterable
                     collapse-tags
                     @change="typeChange"
                     @remove-tag="removeType">
            <el-option v-for="(item1,index) in brandList"
                       :key="index"
                       :label="item1.name"
                       :value="item1.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4"
                v-if="isType">
          <el-select v-model="typeListSelect"
                     multiple
                     filterable
                     collapse-tags
                     @change="brandTypeChange"
                     placeholder="选择型号">
            <el-option v-for="(item,index) in typeList"
                       :key="index"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>

        </el-col>
        <el-col :span="4"
                v-if="stuffList!=[]">
          <el-select v-model="stuffListSelect"
                     placeholder="材质"
                     :multiple='true'
                     filterable
                     collapse-tags
                     :disabled="stuffDisable"
                     @change="stuffChange"
                     @remove-tag="removeStuff">
            <el-option v-for="(item1,index) in stuffList"
                       :key="index"
                       :label="item1.value"
                       :value="item1.id">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4"
                v-for="(item,index) in extrasList"
                :key="item.id+index"
                filterable>
          <el-select v-model="extrasListSelect[index]"
                     :placeholder="item.name"
                     collapse-tags
                     multiple>
            <el-option v-for="item in extrasList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-card>
    <!-- <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  inject: ['reload'],

  components: {},
  //props: ['filterViliable'],
  data() {
    return {
      //dialogVisible: false,
      filtersList: [],
      modelList: [],
      agentList: [],
      supplyList: [],
      expressList: [],
      attrsList: [],
      extrasList: [],
      modelListSelect: '',
      agentListSelect: '',
      supplyListSelect: '',
      expressListSelect: '',
      attrsListSelect: [],
      extrasListSelect: [],
      typeList: [],
      typeListSelect: [],
      stuffList: [],
      stuffListSelect: [],
      brandList: [],
      brandListSelect: [],
      auditName: '',
      isModel: true,
      isType: false,
      oldOptions: [],
      oldOptions1: [],
      oldOptions2: [],
      oldOptions3: [],
      oldOptions4: [],
      stuffDisable: false,
    }
  },
  //   watch: {
  //     filterViliable(newVal, oldVal) {
  //       this.dialogVisible = newVal
  //     },
  //     dialogVisible(newVal, oldVal) {
  //       this.$emit('closeAddFilter', newVal)
  //     },
  //   },
  computed: {},
  methods: {
    //异步获取品牌列表
    asyncFuncBrand() {
      return new Promise(async (resolve) => {
        //获取所有的品牌
        let data=await this.$api.matedataApi.allBrands()
        if (data.code != 0) {
            this.$message({
              message: '品牌获取失败',
              type: 'error',
            })
            return
          }
          resolve(data.data)
      }).catch((error) => {
      })
    },
    //异步获取型号列表
    asyncFuncType(id) {
      return new Promise(async (resolve) => {
        //获取品牌下面的所有型号
        let data=await this.$api.matedataApi.brandTypes({
            brand_id: id,
          })
          if (data.code != 0) {
            this.$message({
              message: '型号获取失败',
              type: 'error',
            })
            return
          }
          resolve(data.data)
      }).catch((error) => {
      })
    },
    //异步获取材质列表
    asyncFuncStuff(id) {
      return new Promise(async (resolve) => {
        //获取所有的可选属性
        let data=await this.$api.productApi.getGoodsAttrOptions({
            attribute_id: id,
          })
          if (data.code != 0) {
            this.$message({
              message: '材质获取失败',
              type: 'error',
            })
            return
          }
          resolve(data.options)
      }).catch((error) => {
      })
    },

    //代理商值改变事件
    agentChange(val) {
      let allValues = []

      //保留所有值
      for (let item of this.agentList) {
        allValues.push(item.id)
      }
      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions1.length === 1 ? this.oldOptions1[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) {
        this.agentListSelect = allValues
      }
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.agentListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.agentListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.agentListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions1[0] = this.agentListSelect
    },
    //快递值改变事件
    expressChange(val) {
      let allValues = []
      //保留所有值
      for (let item of this.expressList) {
        allValues.push(item.id)
      }

      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions2.length === 1 ? this.oldOptions2[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) this.expressListSelect = allValues
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.expressListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.expressListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.expressListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions2[0] = this.expressListSelect
    },
    //供应商值改变事件
    supplyChange(val) {
      let allValues = []
      //保留所有值
      for (let item of this.supplyList) {
        allValues.push(item.id)
      }

      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions3.length === 1 ? this.oldOptions3[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) this.supplyListSelect = allValues
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.supplyListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.supplyListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.supplyListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions3[0] = this.supplyListSelect
    },
    //型号值改变事件
    brandTypeChange(val) {
      let allValues = []
      //保留所有值
      for (let item of this.typeList) {
        allValues.push(item.id)
      }

      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions4.length === 1 ? this.oldOptions4[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) this.typeListSelect = allValues
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.typeListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.typeListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.typeListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions4[0] = this.typeListSelect
    },
    //代理商值改变事件
    agentChange(val) {
      let allValues = []

      //保留所有值
      for (let item of this.agentList) {
        allValues.push(item.id)
      }
      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions1.length === 1 ? this.oldOptions1[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) {
        this.agentListSelect = allValues
      }
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.agentListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.agentListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.agentListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions1[0] = this.agentListSelect
    },
    //材质值改变事件
    stuffChange(val) {
      let allValues = []
      //保留所有值
      for (let item of this.stuffList) {
        allValues.push(item.id)
      }

      // 用来储存上一次的值，可以进行对比
      const oldVal = this.oldOptions.length === 1 ? this.oldOptions[0] : []
      // 若是全部选择
      if (val.includes('ALL_SELECT')) this.stuffListSelect = allValues
      // 取消全部选中  上次有 当前没有 表示取消ALL_SELECT
      if (oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT'))
        this.stuffListSelect = []

      // 点击非全部选中  需要排除全部选中 以及 当前点击的选项
      // 新老数据都有全部选中
      if (oldVal.includes('ALL_SELECT') && val.includes('ALL_SELECT')) {
        const index = val.indexOf('ALL_SELECT')
        val.splice(index, 1) // 排除ALL_SELECT选项
        this.stuffListSelect = val
      }

      //ALL_SELECT未选 但是其他选项全部选上 则ALL_SELECT选上 上次和当前 都没有ALL_SELECT
      if (!oldVal.includes('ALL_SELECT') && !val.includes('ALL_SELECT')) {
        if (val.length === allValues.length - 1)
          this.stuffListSelect = ['ALL_SELECT'].concat(val)
      }

      //储存当前最后的结果 作为下次的老数据
      this.oldOptions[0] = this.stuffListSelect
    },
    //品牌值改变事件
    typeChange(val) {
      //获取型号
      let arr = []
      this.typeList = []
      this.typeListSelect = []
      this.$set(this.typeList, 0, { id: 'ALL_SELECT', name: '全部' })

      val.forEach(async (item, index) => {
        arr = await this.asyncFuncType(item)
        this.typeList.push(...arr)
        this.isType = true
      })
    },
    //移除品牌事件
    removeType(val) {
      if (this.brandListSelect.length == 0) {
        this.isType = false
      }
    },
    //移除材质事件
    removeStuff(val) {},

    //模型值改变事件
    async modelChange(val) {
      if (val) {
        //获取产品模型配置的所有属性
        let data=await this.$api.productApi.getGoodsModelAttributes({
            model_id: val,
          })
        if (data.code != 0) {
            this.$message({
              message: '模型属性获取失败',
              type: 'error',
            })
            return
          }
          this.attrsList = data.attrs
          this.extrasList = data.extraAttrs
          this.attrsList.forEach(async (item, index) => {
            if (item.name_en == 'type_id') {
              let arr = await this.asyncFuncBrand()
              arr.forEach((item1, index1) => {
                this.$set(this.brandList, index1, item1)
              })
            }
            if (item.name_en == 'stuff_id') {
              let arr = await this.asyncFuncStuff(item.id)
              this.$set(this.stuffList, 0, { id: 'ALL_SELECT', value: '全部' })

              arr.forEach((item1, index1) => {
                this.$set(this.stuffList, index1 + 1, item1)
              })
            }
          })
          this.isModel = false
      }
    },
    //模型移除事件
    clearModel(e) {
      this.isModel = true
    },
    //确认新增审核条件
    async addAudit() {
      if (this.modelListSelect == '' || this.auditName == '') {
        this.$message({
          message: '审核名称或模型缺失',
          type: 'warning',
        })
        return
      }
      let isSelect = false
      if (this.stuffListSelect.length == 0 && this.typeListSelect.length == 0) {
        isSelect = false
      } else {
        isSelect = true
      }
      if (!isSelect) {
        try {
          this.extrasListSelect.forEach((item, index) => {
            if (item.length == 0) {
              this.$message({
                message: '参数缺失',
                type: 'warning',
              })
              foreach.break = new Error('StopIteration')
            }
          })
        } catch (error) {
          if (error.message === 'foreach is not defined') {
            return
          } else throw e
        }
      }
      if (this.agentListSelect.includes('ALL_SELECT'))
        this.agentListSelect.splice(0, 1)
      if (this.typeListSelect.includes('ALL_SELECT'))
        this.typeListSelect.splice(0, 1)

      if (this.expressListSelect.includes('ALL_SELECT'))
        this.expressListSelect.splice(0, 1)

      if (this.supplyListSelect.includes('ALL_SELECT'))
        this.supplyListSelect.splice(0, 1)
      if(this.stuffListSelect.includes('ALL_SELECT'))
        this.stuffListSelect.splice(0,1)
      let products = {};
      if(this.typeListSelect.length>0)
      {
        products['type_id'] = this.typeListSelect
      }
      if(this.stuffListSelect.length)
      {
        products['stuff_id'] = this.stuffListSelect
      }
      let text =   JSON.stringify(products)
      let data=await this.$api.flowApi.createFilter({
          name: this.auditName,
          model_id: this.modelListSelect,
          goodsFilter: text,
          agentFilter: JSON.stringify(this.agentListSelect),
          expressFilter: JSON.stringify(this.expressListSelect),
          supFilter: JSON.stringify(this.supplyListSelect),
        })
        if (data.code != 0) {
          if (data.code == 500) {
            this.$message({
              message: data.msg,
              type: 'error',
            })
          }
          return
        }
        if (data.code == 0) {
          this.$message({
            message: '新增成功',
            type: 'success',
          })
          this.reload()
        }
    },
  },
  async created() {
    //获取存在过滤条件和对应过滤结果
    let data =await this.$api.flowApi.getFilters()
    if (data.code != 0) {
        this.$message({
          message: '查询条件获取失败',
          type: 'error',
        })
        return
      }
      this.filtersList = data.items
      //获取模型
    let data1 =await this.$api.productApi.getGoodsModels()
    if (data1.code != 0) {
        this.$message({
          message: '模型获取失败',
          type: 'error',
        })
        return
      }
      this.modelList = data1.goodsmodels
      //获取可选代理
    let data2 =await this.$api.agentApi.getAgents()
    if (data2.code != 0) {
        this.$message({
          message: '代理获取失败',
          type: 'error',
        })
        return
      }
      this.agentList = data2.items
      this.$set(this.agentList, 0, { id: 'ALL_SELECT', name: '全部' })
    //获取可选快递
    let data3 =await this.$api.expressApi.getAllExpress()
    if (data3.code != 0) {
        this.$message({
          message: '快递商获取失败',
          type: 'error',
        })
        return
      }
      this.expressList = data3.items
      this.$set(this.expressList, 0, { id: 'ALL_SELECT', name: '全部' })
    //获取可选供应商
    let data4 =await this.$api.supplierApi.getAllSuppliers()
    if (data4.code != 0) {
        this.$message({
          message: '供应商获取失败',
          type: 'error',
        })
        return
      }
      this.supplyList = data4.suppliers
      this.$set(this.supplyList, 0, { id: 'ALL_SELECT', name: '全部' })
  },

  mounted() {},
}
</script>
<style lang="less" scoped>
.wrapper1 {
    width: 100%;
  /deep/.el-dialog__header {
    background-color: #eee;
  }
  /deep/.el-dialog__body {
    padding: 0;
    height: 400px;
    .el-container {
      height: 100%;
      .clearfix {
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background-color: #aaa;
      }
      .el-aside {
        border: 1px solid #aaa;
        margin-top: 5px;
        margin-left: 5px;
        padding: 5px;
        .el-row {
          margin: 20px 0 !important;
        }
        .box-card {
          height: 100%;
          overflow: scroll;
        }
      }
      .el-main {
        .condition {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .box-card1 {
            width: 45%;
            height: 200px;
            margin: 20px;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    overflow-x: hidden;
  }
}
</style>
