<!--
 * @Author: your name
 * @Date: 2020-08-18 16:57:37
 * @LastEditTime: 2020-09-12 14:30:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \kysystemd:\source\web\src\views\modules\productFlow\filtration\index.vue
-->
<template>
  <div class="wrapper" style="box-shadow: none">
    <!-- <el-card class="top">
      <el-link type="primary"
               @click="addFilters">添加高级查询</el-link>
      <el-link type="success"
               style="margin-left:30px">查看操作记录<i class="el-icon-view el-icon--right"></i></el-link>
    </el-card> -->
    <addFilters
      :filterViliable="filterViliable"
      @closeAddFilter="closeAddFilter"
    />
    <filters />
  </div>
</template>

<script>
import filters from "./filters/index";
import addFilters from "./createdFilter/index";
export default {
  nameL: "filter",
  components: {
    filters,
    addFilters,
  },
  props: {},
  data() {
    return {
      filterViliable: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    //打开新增查询弹框
    addFilters() {
      this.filterViliable = true;
    },
    //关闭新增查询弹窗
    closeAddFilter(val) {
      this.filterViliable = val;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper {
  /deep/.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .top {
    /deep/.el-card__body {
      padding: 8px !important;
    }
    /deep/.el-card.is-always-shadow {
      box-shadow: none;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
</style>