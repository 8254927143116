<template>
  <div class="wrapper2">
    <table-container :list="filtersList">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="ID" width="50"> </el-table-column>
      <el-table-column prop="name" label="高级查询名称"> </el-table-column>
      <el-table-column prop="agent_filter" label="代理">
        <template>
          <el-link type="primary">主要链接</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="express_filter" label="快递"> </el-table-column>
      <el-table-column prop="sup_filter" label="供应商"> </el-table-column>
      <el-table-column prop="express_filter" label="快递"> </el-table-column>
      <el-table-column prop="commited_order_count" label="已提交订单数">
      </el-table-column>
      <el-table-column prop="commited_goods_count" label="已提交商品数">
      </el-table-column>
      <el-table-column prop="checked_commited_order_count" label="已审核订单数">
      </el-table-column>
      <el-table-column prop="checked_commited_goods_count" label="已审核商品数">
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template slot-scope="scope">
          <el-button type="primary" @click="checkOrder(scope.row)" size="mini"
            >快捷审核</el-button
          >
          <el-button
            type="success"
            @click="packageOrder(scope.$index, scope.row)"
            size="mini"
            >快捷打包</el-button
          >
        </template>
      </el-table-column>
    </table-container>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="15"
      :total="total"
      :hide-on-single-page="filtersList.length <= 5"
      @current-change="currentChange"
    >
    </el-pagination>
    <el-dialog title="打包批次" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" :rules="rules" ref="formData">
        <el-form-item label="订单数:" label-width="90px">
          <span>{{ orderNum }}</span>
        </el-form-item>
        <el-form-item label="商品数:" label-width="90px">
          <span>{{ goodsNum }}</span>
        </el-form-item>
        <el-form-item label="批次名称:" label-width="90px" prop="name">
          <el-input
            placeholder="设置打包的批次名称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量:" label-width="90px" prop="packCount">
          <el-input
            placeholder="设置每个批次的订单项数目"
            v-model.number="form.packCount"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="surePack">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      filtersList: [],
      total: 0,
      dialogVisible: false,
      orderNum: 0,
      goodsNum: 0,
      filter_id: "",
      all_order_item_ids: [],
      form: {
        name: "",
        packCount: "",
      },
      rules: {
        name: [
          { required: true, message: "批次名称不能为空", trigger: "blur" },
        ],
        packCount: [
          { required: true, message: "批次数量不能为空", trigger: "blur" },
          {
            pattern: /^[1-9]\d*$/,
            message: "数字格式的哦",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    //审核条件页码改变事件
    async currentChange(page) {
      let data = await this.$api.flowApi.getFilters({
        page: page,
        pageSize: 5,
      });
      if (data.code != 0) {
        this.$message({
          message: "审核获取失败",
          type: "error",
        });
        return;
      }
      this.filtersList = data.items;
      this.total = data.total;
    },
    //快捷批量审核订单
    async checkOrder(scope) {
      if (scope.commited_goods_count <= 0 || !scope.commited_goods_count) {
        this.$message({
          message: "没有可审核得订单项",
          type: "warning",
        });
        return;
      }
      let data = await this.$api.flowApi.quickCheckOrderItems({
        filter_id: scope.id,
        order_item_ids: scope.commited_order_item_ids.join(","),
      });
      if (data.code != 0) {
        this.$message({
          message: "审核失败",
          type: "error",
        });
        return;
      }
      if (data.code == 0) {
        this.$message({
          message: "审核成功",
          type: "success",
        });
        let data1 = await this.$api.flowApi.stasticsFilterCount({
          filter_ids: scope.id,
          status: 1,
        });
        if (data1.code != 0) {
          this.$message({
            message: "获取失败",
            type: "error",
          });
          return;
        }
        if (data1.code == 0) {
          scope.checked_commited_goods_count = data1["0"].goods_count;
          scope.checked_commited_order_count = data1["0"].order_count;
          let data2 = await this.$api.flowApi.stasticsFilterCount({
            filter_ids: scope.id,
            status: 0,
          });
          if (data2.code != 0) {
            this.$message({
              message: "获取失败",
              type: "error",
            });
            return;
          }
          if (data.code == 0) {
            scope.commited_goods_count = data2["0"].goods_count;
            scope.commited_order_count = data2["0"].order_count;
          }
        }
      }
    },
    //批量打包
    packageOrder(index, scope) {
      if (
        scope.checked_commited_order_count <= 0 ||
        !scope.checked_commited_order_count
      ) {
        this.$message({
          message: "没有可打包的订单项",
          type: "warning",
        });
        return;
      }
      this.orderNum = scope.checked_commited_order_count;
      this.goodsNum = scope.checked_commited_goods_count;
      this.filter_id = scope.id;
      this.all_order_item_ids = scope.checked_commited_order_item_ids;
      this.filterIndex = index;

      this.dialogVisible = true;
    },
    //确认打包
    surePack() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          if (this.form.packCount > this.goodsNum) {
            this.$message({
              message: "数额超限",
              type: "warning",
            });
            return;
          }
          this.form["filter_id"] = this.filter_id;
          this.form["all_order_item_ids"] = JSON.stringify(
            this.all_order_item_ids
          );
          this.form["packCount"] = parseInt(this.form.packCount);
          let data = await this.$api.flowApi.quickPackOrderItems(this.form);
          if (data.code != 0) {
            this.$message({
              message: "打包失败",
              type: "error",
            });
            return;
          }
          if (data.code == 0) {
            this.$message({
              message: "打包成功",
              type: "success",
            });
            this.dialogVisible = false;
            let data1 = await this.$api.flowApi.stasticsFilterCount({
              filter_ids: scope.id,
              status: 1,
            });
            if (data1.code != 0) {
              this.$message({
                message: "获取失败",
                type: "error",
              });
              return;
            }
            if (data1.code == 0) {
              this.filtersList[this.filterIndex].checked_commited_goods_count =
                data.data["0"].goods_count;
              this.filtersList[this.filterIndex].checked_commited_order_count =
                data.data["0"].order_count;
              this.filtersList[
                this.filterIndex
              ].checked_commited_order_item_ids = data.data["0"].order_item_ids;
            }
          }
        }
      });
    },
  },
  async created() {
    let data = await this.$api.flowApi.getFilters({
      page: 1,
      pageSize: 5,
    });
    if (data.code != 0) {
      this.$message({
        message: "审核条件获取失败",
        type: "error",
      });
      return;
    }
    this.filtersList = data.items;
    this.total = data.total;
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper2 {
  width: 100%;
  margin-top: 10px;
  /deep/.el-pagination {
    text-align: left;
  }
}
</style>